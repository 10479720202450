export enum Actions {
  Update = 'Update',
  View = 'View',
  Add = 'Add',
  Download = 'Download',
  EnableSetting = 'EnableSetting',
  UseFeature = 'UseFeature',
  Search = 'Search',
}

export enum Subjects {
  AccountPassword = 'AccountPassword',
  TeamMember = 'TeamMember',
  TeamSSOBanner = 'TeamSSOBanner',
  Clips = 'Clips',
  Wav = 'wav',
  Ogg = 'ogg',
  Mp3 = 'mp3',
  Script = 'Script',
  GlobalAudioFileFormat = 'GlobalAudioFileFormat',
  MultipleTTSVersions = 'MultipleTTSVersions',
  Churnkey = 'Churnkey',
  Projects = 'Projects',
  ThirdPartyVoices = 'ThirdPartyVoices',
}

export const SubscriptionTypes = {
  WslAdmin: 'wslAdmin',
  Producer: 'producer',
  Maker: 'maker',
  Creative: 'creative',
  Trial: 'trial',
  Team: 'team',
} as const;

export type SubscriptionTypesEnum =
  (typeof SubscriptionTypes)[keyof typeof SubscriptionTypes];
